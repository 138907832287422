@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin setRemToProp($property, $base, $values...) {
  $max: length($values);
  $remValues: '';
  $base: strip-unit($base);

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValue: #{$value / $base}rem;
    $remValues: #{$remValues} #{$remValue};
  }

  #{$property}: $remValues;
}

@mixin media($min: null, $max: null) {
  @if $min == null and $max != null {
    @media #{'#{$screen} and (max-width: #{$max})'} {
      @content;
    }
  } @else if $min != null and $max == null {
    @media #{'#{$screen} and (min-width: #{$min})'} {
      @content;
    }
  } @else {
    @media #{'#{$screen} and (min-width: #{$min}) and (max-width: #{$max})'} {
      @content;
    }
  }
}

@mixin text-input {
  width: 100%;
  border: 1px solid $bona-border-grey;
  border-radius: 4px;
  padding: 10px;
  font-family: $font-family;
  font-size: 16px;
  background-color: white;
  font-weight: 400;
  height: 48px;

  &[disabled] {
    color: #848688;
    background-color: #edeeee;
    border: #edeeee 1px solid;
    cursor: not-allowed;
  }

  &::placeholder {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1.03px;
    color: #605f63;
    opacity: 0.3;
  }
}

@mixin input-has-error {
  border: $color-error 1px solid;
}

@mixin textarea {
  width: 100%;
  padding-top: 12px;
  border: 1px solid $bona-border-grey;
  border-radius: 3px;
  padding: 10px;
  font-family: $font-family;
  font-size: 16px;
  &[disabled] {
    color: #606063;
    background-color: $mercury;
    border: $mercury 1px solid;
  }
}

@mixin icon-inside-input {
  stroke: $near-black;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

@mixin label {
  width: auto;
  font-size: calc(1rem - 2px);
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0;
  font-family: $font-family;
  display: block;
}

@mixin select {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
  font-family: $font-family;
  border: $tundora 1px solid;
  appearance: none;
  padding-right: 40px;
  background: url(/Dist/img/icons/arrow-down-tundora.svg) no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
  font-size: 14px;
  :global(body.IE) & {
    background: none;
    padding-right: 10px;
  }

  @media #{$md-up} {
    font-size: 16px;
  }
}

@mixin btn {
  @include HelveticaMedium;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  padding: 12px 22px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin textBtn {
  border: none;
  font-weight: 300;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  background: transparent;
  font-size: inherit;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
}

@mixin zoomBtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
  width: 32px;
  height: 32px;
  border: 1px solid $midnight-blue;
  border-radius: 4px;

  &:hover {
    background-color: $bona-spot-blue;
  }
}

@mixin disabledButton {
  pointer-events: none;
  background-color: $light-gray !important;
  color: $bona-dark-grey !important;

  svg {
    path {
      stroke: $bona-dark-grey !important;
    }
  }
}

@mixin isLoading {
  pointer-events: none;
  cursor: not-allowed;
  filter: blur(2px);
}

@mixin zoojaFont {
  font-family: 'ZoojaLight', 'Zooja';
  font-size: 2.5rem;
}

@mixin HelveticaMedium {
  font-family: 'Helvetica Neue LT W05_65 Medium';
}

@mixin HelveticaBold {
  font-family: 'Helvetica Neue LT W05_65 Medium';
  font-weight: 700;
}

@mixin HelveticaRegular {
  font-family: 'Helvetica Neue LT W05_55 Roman';
  font-weight: 400;
}
