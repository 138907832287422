/* 
* COLORS 
* color names is generate through http://chir.ag/projects/name-that-color/
*/
$vida-loca: #638c1c;
$eden: #145a4e;
$casablanca: #f3bd48;
$black: #1c1b21;
$irish-coffee: #672f25;
$whiskey: #cda075;
$darkmidnight_blue: #00153d;
$midnight_border: #2d3e5f;
$midnight-blue: #00205c;
$lightest-blue: #f5f8fe;
$cerulean: #00a0df;
$cornflower: #8ab7e9;
$orange: #ff671d;
$maroon-flush: #ae1857;
$shiraz: #c40d3e;
$error: #ff3a31;
$pampas: #f2f0ea;
$spring-wood: #f5f3ed;
$mercury: #e1e0e0;
$silver: #c2c2c2;
$gray-chateau: #a7a8a9;
$jumbo: #77767a;
$corduroy: #6f7271;
$scarpa-flow: #605f63;
$tundora: #4c4c4c;
$alabaster: #fbfbfb;
$alto: #d8d8d8;
$white: #ffffff;
$light-gray: #f1f3f4;
$dark-gray: #707378;
$darkest-gray: #5f6368;
$near-black: #202124;
$light-blue: #004fe0;
$clear-blue: #eef2fd;
$near-white: #f8f9f9;
$light-cyan: #dee1e6;
$hover-blue: #0041b7;
$blue-mid: #d8e3fa;
//New Design colors
$color-modal-bg-transparent: rgba(32, 33, 36, 0.4);
$color-error-bg: rgba(255, 0, 0, 0.1);
$color-error-txt: red;

//Blue
$bona-light-blue: #eef2fd;
$bona-spot-blue: #d3e1ff;
$bona-dark-blue: #00153d;

//Grey
$bona-border-grey: #babcbe;
$bona-dark-grey: #babcbe;
$bona-light-grey: #dee1e6;

$color-brand: $midnight-blue;

$color-primary-1: $vida-loca;
$color-primary-2: $casablanca;
$color-primary-3: $cerulean;

$color-discount: $orange;
$color-cta: $color-brand;
$color-cta-light: $light-blue;
$color-find-reseller: $orange;

$color-success: #4ed964;
$color-info: $cerulean;
$color-error: $shiraz;

$text-color: $black;
$text-color-tone-down: $jumbo;

$color-primary-bg: white;
$color-secondary-bg: $pampas;

$color-heading-highlight: $orange;

$color-modal-bg: $pampas;
$color-modal-bg-transparent-dark: rgba(0, 0, 0, 0.7);

$color-footer: $darkmidnight_blue;

$color-product-page-top-section-bg: $white;
$color-product-page-related-items-section-bg: $near-white;
$color-product-page-tabs-md-up: #fff;

$color-tabs-nav-border-phone: $light-gray;
$color-tabs-nav-selected: $color-brand;
$color-tabs-nav-selected-text: $midnight-blue;
$color-tabs-nav-selected-bg: $white;
$color-tabs-nav-text: $white;
$color-tabs-nav-hover: $blue-mid;
$color-mega-menu-desc: $darkest-gray;
$color-mega-menu-img-desc: $near-black;
$color-mega-menu-image-hover: $clear-blue;

$color-quick-search-placeholder: $tundora;
$color-quick-search-top-suggestion-links: $tundora;

$color-search-page-bg: $pampas;

$color-skeleton-items: #efefef;

$color-info-area-bg: white;

$color-cart-nbr-bg: $light-blue;
$color-cart-nbr-text: white;

$color-checkout-page: $white;
$color-my-pages-bg: $pampas;

$color-order-confirmation-page: $color-checkout-page;

$color-impersonation-area: $maroon-flush;

$color-header-quicklinks: $light-gray;
$color-header-quicklinks-secondary: $dark-gray;
$color-bona-blue: $midnight-blue;
$color-bona-lightest-blue: $lightest-blue;

$color-bona-professional: #f2f2f2;
$color-bona-homeowner: #faf6f1;

/*
 * MEDIA VARIABLES
 *
 * ** WARNING **
 *
 * If changing values, apply changes to CssConfig.js as well
 *
 */
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 1158px;
$screen-lg-min: $screen-lg;

// Extra large screen / wide desktop
$screen-xl: 1720px;
$screen-xl-min: $screen-xl;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$screen: 'only screen';
$xs-up: $screen;
$xs-only: '#{$screen} and (max-width: #{$screen-xs-max})';

$sm-down: '#{$screen} and (max-width: #{$screen-sm-max})';
$sm-up: '#{$screen} and (min-width: #{$screen-sm-min})';
$sm-only: '#{$screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})';

$md-down: '#{$screen} and (max-width: #{$screen-md-max})';
$md-up: '#{$screen} and (min-width: #{$screen-md-min})';
$md-only: '#{$screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})';

$lg-up: '#{$screen} and (min-width: #{$screen-lg-min})';

$xl-up: '#{$screen} and (min-width: #{$screen-xl-min})';

$rootLockedClass: '.rootlocked';
/*
* GRID 
*/
$gutterContentArea: 15px;
$gutterBottomXS: 30px;
$gutterBottomSM: 15px;
$content-container-width: 1158px - $gutterContentArea * 2;
$app-max-width: 1920px;
/*
* BLOCK
*/
$verticleBlockPaddingXS: 48px;
$verticleBlockPaddingMD: 80px;
/* 
* TYPOGRAPHY 
*/
$font-family: 'Helvetica Neue LT W05_55 Roman',
  'Helvetica Neue LT W05_65 Medium', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica', Oxygen-Sans, Ubuntu, Cantarell sans-serif;
$font-family-fallback: Arial, Helvetica, sans-serif;
$base-font-size: 14px;
$base-font-size-md: 16px;

$line-height-none: 1;
$line-height-tight: 1.25;
$line-height-snug: 1.375;
$line-height-normal: 1.5;
$line-height-relaxed: 1.625;
$line-height-loose: 2;

$line-height: $line-height-relaxed;
$line-height-md: $line-height-relaxed;

/* 
* ANIMATION 
*/
$main-timing-function: ease-in-out;

/* 
* Map of z-indexes. Should not contain any negative or zero values.
*/
$zIndexes: (
  tabsBtn: 10,
  product-page-variants-tab-item-wrapper: 10,
  productCard: 10,
  documentCard: 10,
  contentCard: 10,
  productCardListDropdown: 90,
  productComplianceContainer: 100,
  filterBtn: 110,
  shareBtn: 130,
  heroVector: 130,
  heroContent: 140,
  heroFormDropdown: 140,
  stickyContentNav: 150,
  loaderInSearchAndCategoryPage: 200,
  fixedBottomContainer: 500,
  mobileMenuFadeOverlay: 600,
  header: 700,
  mobileMenuContent: 800,
  modal: 650,
  modalNoHeader: 800,
  paymentSectionFixed: 900,
  loadingFixedCircle: 1000,
  mobileMenuLoadingDotsWrapper: 1000,
);

/*
* Various settings, refactor if too long
*/
/* product page */
$min-height-md-up-product-page-tabs: 300px;
/* header */
$standard-header-height-md-up: 108px; //if changed update in CssConfig.js
$standard-header-minimal-height-md-up: 82px;
$full-width-header-height-sm-up: 80px;
$mobile-header-height: 56px; //if changed update in CssConfig.js
/*checkout*/
$breakpoint-addToCartByArticleNbr-hide-clear-cart: 450px;
$bona-drop-shadow: drop-shadow(0px 4px 20px rgba(95, 99, 104, 0.08));
$bona-box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
$min-width-search-box: 330px;
$search-box-gap: 16px;
/*spare parts cart modal*/
$desktop-small-height: 820px;
